<div class="container">
    <div class="container py-0">
        <div class="row">
            <div class="col-12">
                <header class="local_header" id="localHeader">
                    <h1>{{content.header}}</h1>
                </header>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md pr-0 pr-md-2"><progress-bar [activeStep]="3"></progress-bar></div>
        </div>
        <div class="content-container">
            <div class="row pt-3">
                <div class="col-md section-subheader-redesign">{{content.subHeader}}</div>
                <div class="col-md d-none d-lg-block"></div>
            </div>
            <div class="row py-3">
                <div class="col-md section-header">ORGANIZATION PROFILE</div>
                <div class="col-md d-none d-lg-block"></div>
            </div>
            <form #organizationForm="ngForm" (ngSubmit)="next($event)" [formGroup]="step3Form">
                <div class="row pb-3">
                    <div class="col-md" formGroupName="owner">
                        <label class="input-section-label" for="title" [class.is-invalid-message]="titleCtrl.errors?.required && organizationForm.submitted">Title</label>
                        <select formControlName="title" class="form-select" style="cursor: pointer; z-index: 5">
                            <option [ngValue]="null" [disabled]="true">Select a title</option>
                            <option *ngFor="let prf of titles" [ngValue]="prf">
                                {{ prf }}
                            </option>
                        </select>
                        <div *ngIf="titleCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-4">
                    <div class="col-md">
                        <label class="input-section-label" for="numberOfEmployees" [class.is-invalid-label]="numberOfEmployeesCtrl.errors?.required && organizationForm.submitted">Approximate number of employees</label>
                        <select formControlName="numberOfEmployees" id="numberOfEmployees" class="form-select" style="cursor: pointer; z-index: 5">
                            <option [ngValue]="null" [disabled]="true">Select a number range</option>
                            <option *ngFor="let nrf of numberOfEmployees" [ngValue]="nrf.id">
                                {{ nrf.value }}
                            </option>
                        </select>
                        <div *ngIf="numberOfEmployeesCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-4">
                    <div class="col-md">
                        <fieldset formGroupName="owner">
                            <label class="input-section-label" style="line-height: 1.5;" [class.is-invalid-label]="isConsultantCtrl.errors?.required && organizationForm.submitted">Are you a consultant or consulting firm who already uses or is planning to use our products in your practice?</label>
                            <div>
                                <label class="custom-radio-container pb-1">
                                    <input type="radio" value="Y" formControlName="isConsultant" class="userradio" /> Yes &nbsp;&nbsp;
                                    <span class="custom-radiomark" mbcKeydown (mbcOnKeydown)="isConsultantCtrl.setValue('Y')"></span>
                                </label>
                                <label class="custom-radio-container">
                                    <input type="radio" value="N" formControlName="isConsultant" class="userradio" /> No
                                    <span class="custom-radiomark" mbcKeydown (mbcOnKeydown)="isConsultantCtrl.setValue('N')"></span>
                                </label>

                            </div>
                            <div *ngIf="isConsultantCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </fieldset>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-4" [hidden]="isConsultantCtrl.value !== 'N'">
                    <div class="col-md">
                        <label id="categoryquestion" class="input-section-label" [class.is-invalid-label]="categoryCtrl.errors?.required && organizationForm.submitted">{{categories?.question}}</label>
                        <select id="category" formControlName="category" class="form-select" style="cursor: pointer; z-index: 5">
                            <option [ngValue]="null" [disabled]="true">Select an industry</option>
                            <option *ngFor="let crf of categories?.values" [ngValue]="crf.categoryId">
                                {{ crf.categoryValue }}
                            </option>
                        </select>
                        <div *ngIf="categoryCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-4" [hidden]="isConsultantCtrl.value !== 'N'|| categoryCtrl.value === null">
                    <div class="col-md">
                        <label id="subcategoryquestion" class="input-section-label" [class.is-invalid-label]="subCategoryCtrl.errors?.required && organizationForm.submitted">{{selectedSubCategory?.question}}</label>
                        <select id="subCategory" formControlName="subCategory" class="form-select" style="cursor: pointer; z-index: 5">
                            <option [ngValue]="null" [disabled]="true">{{selectedSubCategory?.placeHolderText}}</option>
                            <option *ngFor="let srf of selectedSubCategory?.values" [ngValue]="srf.subCategoryId">
                                {{ srf.subCategoryValue }}
                            </option>
                        </select>
                        <div *ngIf="subCategoryCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div *ngIf="selectedCountry?.countryCode==='US'" class="row pb-3">
                    <div class="col-md">
                        <label for="isTaxExempt" class="register-check-container" style="line-height: 19px;">
                            <input type="checkbox" id="isTaxExempt" formControlName="isTaxExempt" style="position: absolute; left: 0; top: 4px;" />
                            <span class="register-checkmark" mbcKeydown (mbcOnKeydown)="isTaxExemptCtrl.setValue(!isTaxExemptCtrl.value);"></span>
                            My organization has <b>state sales tax exemption status</b> and can provide a state sales tax exemption certificate to The Myers-Briggs Company.
                            <p [hidden]="isTaxExemptCtrl?.value !== true" style="opacity: 0.8;margin-top: 14px;">
                                <strong><em>Note: </em></strong>After you register, you will be emailed instructions for providing your state sales tax exemption certificate.
                            </p>
                        </label>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-3">
                    <div class="col-md section-header">ORGANIZATION ADDRESS</div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <fieldset formGroupName="address">
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" style="opacity: 0.6;">Country</label>

                            <span class="d-block" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>You cannot change the country you selected. To continue, please enter your organization's address below.</span>">
                                <input id="organizationCountry" formControlName="countryName" type="text" readonly="readonly" class="form-input" style="cursor: not-allowed; opacity: 0.6;" />
                            </span>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" [class.is-invalid-label]="addressLine1Ctrl.errors?.required && organizationForm.submitted">Address 1</label>
                            <input type="text" id="address1" formControlName="addressLine1" placeholder="Enter street address" class="form-input" maxlength="100" autocomplete="off" />
                            <div *ngIf="addressLine1Ctrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label">Address 2 <span class="field-header-note">(optional)</span></label>
                            <!--<div>-->
                            <input type="text" id="address2" formControlName="addressLine2" placeholder="Enter street address" class="form-input" maxlength="100" autocomplete="off" />
                            <!--</div>-->
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <div id="address3" [hidden]="showAddress3Link">
                                <label class="input-section-label">Address 3 <span class="field-header-note">(optional)</span></label>
                                <!--<div>-->
                                <input type="text" id="address3" formControlName="addressLine3" placeholder="Enter street address" class="form-input" maxlength="100" autocomplete="off" />
                                <!--</div>-->
                            </div>
                            <a id="address3Link" [routerLink]="" (click)="toggleAddress3()" class="std-primary-link" style="font-size: 14px !important;" [hidden]="!showAddress3Link">add address line</a>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" [class.is-invalid-label]="cityCtrl.errors?.required && organizationForm.submitted">Town/City</label>
                            <input type="text" id="city" formControlName="city" placeholder="Enter town or city" class="form-input" maxlength="30" autocomplete="off" />
                            <div *ngIf="cityCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" [class.is-invalid-label]="stateOrRegionCtrl.errors?.required && organizationForm.submitted">County/Region/State</label>
                            <select *ngIf="!(selectedCountry?.stateProvinceOptional)" id="ddlState" formControlName="stateOrRegion" class="form-select" style="cursor: pointer;">
                                <option [ngValue]="null" [disabled]="true">Select county, region or state</option>
                                <option *ngFor="let rgn of regions" [ngValue]="rgn.abbreviation">
                                    {{rgn.name}}
                                </option>
                            </select>
                            <input *ngIf="(selectedCountry?.stateProvinceOptional)" id="txtState" formControlName="stateOrRegion" class="form-input" />
                            <div *ngIf="stateOrRegionCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                </fieldset>
                <div class="row pb-3">
                    <div class="col-md">
                        <div class="container py-0">
                            <div class="row">
                                <div class="col-md-5 col-sm-12 pl-0 pr-0 pr-sm-1 pb-3 pb-md-0" formGroupName="address">
                                    <label class="input-section-label" [class.is-invalid-label]="postalCodeCtrl.errors?.required && organizationForm.submitted">Postal code/ZIP</label>
                                    <input type="text" id="zip" formControlName="postalCode" placeholder="Enter postal or zip code" class="form-input" maxlength="30" autocomplete="off" />
                                    <div *ngIf="postalCodeCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                                    <div *ngIf="postalCodeCtrl.errors?.postal_code?.postal_code_bad_format && organizationForm.submitted"><label class="is-invalid-message">{{postalCodeCtrl.errors?.postal_code?.message}}</label></div>
                                </div>
                                <div class="col-md-7 col-sm-12 pl-sm-1 pl-0 pr-0">
                                    <label class="input-section-label" [class.is-invalid-label]="phoneNumberCtrl.errors?.required && organizationForm.submitted">Work Number</label>
                                    <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="searchCountryFields"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="selectedCountryIso"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="true"
                                                        [numberFormat]="numberFormat"
                                                        placeholder="Enter your work number"
                                                        name="phoneNumber"
                                                        formControlName="phoneNumber">
                                    </ngx-intl-tel-input>
                                    <div *ngIf="phoneNumberCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                                    <div *ngIf="phoneNumberCtrl.errors && !phoneNumberCtrl.errors?.required"><label class="is-invalid-message">Work phone is not valid. Please ensure formatting is correct.</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md d-none d-lg-block">
                        <!--<p>{{organizationForm.valid}}</p>
                <p>{{organizationForm.value | json}}</p>-->
                        

                    </div>
                    

                </div>
                <div class="row pb-3">
                    <div class="col-md">
                        <div class="form-control-registration">
                            <label class="input-section-label" for="defaultTimeZone" [class.is-invalid-label]="defaultTimeZoneCtrl.errors?.required && organizationForm.submitted">Time Zone</label>
                            <span style="margin-left:5px;" class="help-icon-container">
                                <a tabindex="0" type="button" class="help-icon" id="default-time-zone-popover" data-toggle="popover" data-trigger="focus" data-template-url="popover-template.html" title="Default Time Zone" data-content="This will be used as your default time zone and can be edited in your account management."></a>
                            </span>
                            <select id="defaultTimeZone" formControlName="defaultTimeZone" class="form-select" style="cursor: pointer;">
                                <option [ngValue]="null" [disabled]="true">Select time zone</option>
                                <option *ngFor="let tz of timeZones" [ngValue]="tz.id">
                                    {{ tz.displayName }}
                                </option>
                            </select>
                            <div *ngIf="defaultTimeZoneCtrl.errors?.required && organizationForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>

                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div [hidden]="!selectedCountry?.isVATCollected" class="row pb-5">
                    <div class="col-md">
                        <label class="input-section-label" [class.is-invalid-label]="vatCtrl.errors?.invalid_format">Business Registration/Tax Information</label>
                        <span style="margin-left:5px;" class="help-icon-container">
                            <a tabindex="0" type="button" class="help-icon" id="global-vat-registration-number-popover" data-toggle="popover" data-trigger="focus" data-template-url="popover-template.html" title="VAT Identification Number" data-content="If your organization has a VAT registration number for taxation purposes, please provide it here.  If you do not have access to the number, you can complete registration now and submit the VAT number later by contacting our customer support team."></a>
                        </span>
                        <input type="text" id="vatRegistrationNumber" formControlName="vatRegistrationNumber" placeholder="Enter VAT registration number" class="form-input" maxlength="20" autocomplete="off" />
                        <div *ngIf="vatCtrl.errors?.invalid_format"><label class="is-invalid-message">Invalid VAT format entered. If you think you have entered it correctly, please complete registration without it and contact our customer support team.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row text-center text-md-right pt-5 pt-md-0">
                    <div class="col-md">
                        <button class="btn-tpl-lg btn-orange-primary has-promise-spinner" [promiseBtn]="nextSpinner" type="submit">
                            <span class="button-text">NEXT</span>
                        </button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
<!--modals-->
<div class="modal fade" id="notValidAddressPopup" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm" style="max-width: 640px;">
        <div class="modal-content">
            <div class="modal-header pt-3 pb-0" style="border-style: none;">
                <div class="modal-title"><div id="modalTitle" class="modal-header-redesign">Verify Your Address</div></div>
                <a class="modal-close pointer" data-dismiss="modal" (click)="closeAddressValidation()" aria-label="Close" tabindex="0" (keydown.enter)="closeAddressValidation()">&nbsp;</a>
            </div>
            <div class="modal-body pt-0">
                <div style="display:inline-block; font-size:16px; margin-top:20px;">
                    <span style="font-family:'OpenSans-SemiBold'">We were not able to verify the address you entered. </span><span>
                        Please edit the address. Or, if you know that the address you entered is correct, click
                    </span><span style="font-family:'OpenSans-SemiBold'">
                        Keep Entered Address.
                    </span>
                </div>
                <div style="margin-top:30px; text-align:center">
                    <div class="address-validation-container" id="enteredAddressContainer" style="display:inline-block; width:60%; min-height:205px;">
                        <div class="address-validation-header">
                            <span style="position: absolute; left: 30px;">
                                <div class="selected-address-icon" style=" display: none"></div>
                            </span>
                            Entered Address
                        </div>
                        <div class="line-copy" style="margin: 0 auto; margin-bottom:15px;"></div>
                        <div class="address-validation-addressline-text" id="notvalidAddressLine1">{{addressLine1Ctrl.value}}</div>
                        <div *ngIf="addressLine2Ctrl.value !== ''" class="address-validation-addressline-text" id="notvalidAddressLine2">{{addressLine2Ctrl.value}}</div>
                        <div *ngIf="addressLine3Ctrl.value !== ''" class="address-validation-addressline-text" id="notvalidAddressLine3">{{addressLine3Ctrl.value}}</div>
                        <div class="address-validation-city-state-zip-text" id="notvalidCityStateZip">{{ cityCtrl.value.concat(!!stateOrRegionCtrl.value  || !!postalCodeCtrl.value  ? ", " : "")?.concat(!!stateOrRegionCtrl.value  ? stateOrRegionCtrl.value : "")?.concat(!!postalCodeCtrl.value  ? " " + postalCodeCtrl.value : "") }}</div>
                        <div class="address-validation-country" id="notvalidCountry">{{countryNameCtrl.value}}</div>
                        <div class="container pt-0 pb-2">
                            <div class="row pt-4">
                                <div class="col-1"></div>
                                <div class="col-1">
                                    <div class="information-icon-container information-icon"></div>
                                </div>
                                <div class="col">
                                    <div class="information-text text-left">This address may be incorrect.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="border-style: none;">

                <div style="margin: 0 auto;margin-top: 20px; margin-bottom: 15px; text-align: center;">
                    <input type="submit" class="btn-tpl-lg btn-orange-primary mb-3" aria-label="edit address" style="width: 150px; margin-left: 1px !important;" value="Edit" data-dismiss="modal" (click)="editAddress($event)" />
                    <a class="std-link-bold-underline text-uppercase pointer pl-2 pl-md-4" aria-label="keep address" data-dismiss="modal" (click)="keepAddress($event)" tabindex="0" (keydown.enter)="keepAddress($event)">Keep entered address</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="enteredAndSuggestedAddressPopup" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" style="min-width: 375px;">
        <div class="modal-content pt-1 px-1 pb-1 pt-sm-3 px-sm-3 pb-sm-2">
            <div class="modal-header pt-4 pb-0" style="border-style: none;">
                <div class="modal-title"><div id="modalTitle" class="modal-header-redesign">Verify Your Address</div></div>
                <a class="modal-close pointer" data-dismiss="modal" (click)="closeAddressValidation()" aria-label="Close" tabindex="0" (keydown.enter)="closeAddressValidation()">&nbsp;</a>
            </div>
            <div class="modal-body p-1 px-sm-3 py-sm-3">
                <div style="display:inline-block; font-size:16px; margin-top:20px;">
                    <span style="font-family:'OpenSans-SemiBold'">We were not able to verify the address you entered. </span>
                    <span>
                        We provided a suggested address below. Please
                        select which version of the address you want to use.
                    </span>
                </div>
                <div id="addressSelectError" class="error_message" style="display:none; margin-bottom:7px;">Please select an address to continue.</div>
                <div class="container">
                    <div class="row">
                        <div class="col esaBox" style="border-top: 2px solid #F0F0F0; border-bottom: 2px solid #F0F0F0;">
                            <div class="address-validation-header text-center" id="enteredAddressHeaderText">
                                <span style="position: absolute; left: 30px;">
                                    <div class="selected-address-icon" id="enteredAddressSuccessIcon" style=" display: none"></div>
                                </span>
                                Entered Address
                            </div>
                        </div>
                        <div class="col esaBox" style="border-top: 2px solid #F0F0F0; border-bottom: 2px solid #F0F0F0;">
                            <div class="address-validation-header text-center" id="suggestedAddressHeaderText">
                                <span style="position: absolute; left: 30px;">
                                    <div class="selected-address-icon" id="suggestedAddressSuccessIcon" style=" display: none"></div>
                                </span>
                                Suggested Address
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- py-3  -->
                        <div class="col text-center esaBox">

                            <div style="padding-top: 16px; padding-bottom: 16px;">
                                <div class="address-validation-addressline-text" id="enteredAddressLine1">{{addressLine1Ctrl.value}}</div>
                                <div *ngIf="addressLine2Ctrl.value !== ''" class="address-validation-addressline-text" id="enteredAddressLine2">{{addressLine2Ctrl.value}}</div>
                                <div *ngIf="addressLine3Ctrl.value !== ''" class="address-validation-addressline-text" id="enteredAddressLine3">{{addressLine3Ctrl.value}}</div>
                                <div class="address-validation-city-state-zip-text" id="enteredAddressCityStateZip">{{ enteredCityStateZip }}</div>
                                <div class="address-validation-country" id="enteredCountry">{{countryNameCtrl.value}}</div>
                            </div>
                        </div>
                        <div class="col text-center esaBox">
                            <!--py-1-->
                            <div style="padding-top: 16px; padding-bottom: 16px;">
                                <div class="address-validation-addressline-text" [class.address-different]="addressLine2Ctrl.value?.toLowerCase() != suggestedAddress.addressLine1.toLowerCase()" id="suggestedAddressLine1">{{suggestedAddress.addressLine1}}</div>
                                <div *ngIf="suggestedAddress.addressLine2 !== ''" class="address-validation-addressline-text" [class.address-different]="false" id="suggestedAddressLine2">{{suggestedAddress.addressLine2}}</div>
                                <div *ngIf="suggestedAddress.addressLine2 !== ''" class="address-validation-addressline-text" [class.address-different]="false" id="suggestedAddressLine3">{{suggestedAddress.addressLine3}}</div>
                                <div class="address-validation-city-state-zip-text" [class.address-different]="enteredCityStateZip.toLowerCase() != suggestedCityStateZip.toLowerCase() " id="suggestedCityStateZip">{{ suggestedCityStateZip }}</div>
                                <div class="address-validation-country" id="suggestedCountry">{{suggestedAddress.countryName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--pb-3-->
                        <div class="col esaBox"><div class="address-validation-addressline-text address-edit-link-option" style="margin-top:10px;padding-bottom: 16px;"><a [routerLink]="" id="editUserAddress" class="std-primary-link" style="font-size: 15px;" data-dismiss="modal" (click)="editAddress($event)">Edit</a></div></div>
                        <div class="col esaBox"><div style="margin-top:10px;padding-bottom: 16px;">&nbsp;</div></div>
                    </div>
                    <div class="row">
                        <!-- pb-2  -->
                        <div class="col text-center esaBox"><div style="padding-bottom: 8px;"><button type="button" class="btn-tpl-sm address-btn-teal-secondary address-button-option" id="selectEnteredAddress" (click)="selectEnteredAddress()"  aria-label="select entered address" tabIndex="0">USE ENTERED ADDRESS</button></div></div>
                        <div class="col text-center esaBox"><div style="padding-bottom: 8px;"><button type="button" class="btn-tpl-sm address-btn-teal-secondary address-button-option" id="selectSuggestedAddress" (click)="selectSuggestedAddress()"  aria-label="select suggested address"  tabindex="0">USE SUGGESTED ADDRESS</button></div></div>
                    </div>
                    <div class="row">
                        <!-- pb-2  -->
                        <div class="col esaBox" style="border-bottom: 2px solid #F0F0F0;">
                            <div class="row">
                                <div class="col-2 pl-1">
                                    <div class="information-icon-container information-icon" style="position: relative;top: 3px; padding-bottom: 8px;"></div>
                                </div>
                                <div class="col-10 pl-0">
                                    <div class="information-text d-inline" style="padding-bottom: 8px;">This address may be incorrect.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col esaBox" style="border-bottom: 2px solid #F0F0F0;"><div style="padding-bottom: 8px;">&nbsp;</div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ValidationServiceDownPopup" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm" style="max-width: 640px;">
        <div class="modal-content">
            <div class="modal-header pt-3 pb-0" style="border-style: none;">
                <div class="modal-title"><div id="modalTitle" class="modal-header-redesign">Verify Your Address</div></div>
                <a class="modal-close pointer" data-dismiss="modal" (click)="closeAddressValidation()" aria-label="Close" tabindex="0" (keydown.enter)="closeAddressValidation()">&nbsp;</a>
            </div>
            <div class="modal-body pt-0">
                <div style="display:inline-block; font-size:16px; margin-top:20px;">
                    <span style="font-family:'OpenSans-SemiBold'">We were not able to verify the address you entered. </span><span>
                        To continue with the address you entered, click
                    </span><span style="font-family:'OpenSans-SemiBold'">
                        Use Entered Address.
                    </span>
                </div>
                <div style="margin-top:30px; text-align:center">
                    <div class="address-validation-container" id="enteredAddressContainer" style="display:inline-block; width:60%; min-height:205px;">
                        <div class="address-validation-header">
                            <span style="position: absolute; left: 30px;">
                                <div class="selected-address-icon" style=" display: none"></div>
                            </span>
                            Entered Address
                        </div>
                        <div class="line-copy" style="margin: 0 auto; margin-bottom:15px;"></div>
                        <div class="address-validation-addressline-text" id="notvalidAddressLine1">{{addressLine1Ctrl.value}}</div>
                        <div *ngIf="addressLine2Ctrl.value !== ''" class="address-validation-addressline-text" id="notvalidAddressLine2">{{addressLine2Ctrl.value}}</div>
                        <div *ngIf="addressLine3Ctrl.value !== ''" class="address-validation-addressline-text" id="notvalidAddressLine3">{{addressLine3Ctrl.value}}</div>
                        <div class="address-validation-city-state-zip-text" id="notvalidCityStateZip">{{ cityCtrl.value.concat(!!stateOrRegionCtrl.value  || !!postalCodeCtrl.value  ? ", " : "")?.concat(!!stateOrRegionCtrl.value  ? stateOrRegionCtrl.value : "")?.concat(!!postalCodeCtrl.value  ? " " + postalCodeCtrl.value : "") }}</div>
                        <div class="address-validation-country" id="notvalidCountry">{{countryNameCtrl.value}}</div>
                        <div class="container pt-0 pb-2">
                            <div class="row pt-4">
                                <div class="col-1"></div>
                                <div class="col-1">
                                    <div class="information-icon-container information-icon"></div>
                                </div>
                                <div class="col">
                                    <div class="information-text text-left">This address may be incorrect.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="border-style: none;">

                <div style="margin: 0 auto;margin-top: 20px; margin-bottom: 15px; text-align: center;">
                    <button type="button" class="btn-tpl-sm address-btn-teal-secondary address-button-option" id="selectEnteredAddress" (click)="selectEnteredAddressNoValidation()"  aria-label="select entered address" tabindex="0">USE ENTERED ADDRESS</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="display: none" id="popover-template.html">
    <div class="popover">
        <div class="arrow"></div>
        <div class="popover-content">
            <div class="close-icon-container elevate-popover-close-icon" ng-click="$hide()">
                <div class="close-icon"></div>
            </div>
            <div class="elevate-popover-heading popover-header"></div>
            <div class="elevate-popover-body popover-body"></div>
        </div>
    </div>
</div>
